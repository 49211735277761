import React, {useState} from 'react';
import './form.css';

const Form = () => {
    const [formData, setFormData] = useState({
        doc_key: '',
        pm_percent: '',
        qa_percent: '',
        team_ratio: '',
        type_estimation: '',
        handler: '',
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState({});
    const [link, setLink] = useState('');
    const [showPercentOptions, setShowPercentOptions] = useState(true);
    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const handleHandlerChange = (e) => {
        if(e.target.value === 'ai'){
            setShowPercentOptions(false);
        }
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const validateNumber = (input) => {
        // Remove any leading or trailing whitespace
        const trimmedInput = input.trim();

        // Define the regex pattern to match numbers only
        const numberPattern = /^[0-9]+$/;

        // Test the input against the number pattern
        if (numberPattern.test(trimmedInput)) {
            // Input contains only numbers
            return true;
        } else {
            // Input contains non-numeric characters
            return false;
        }
    }

    const validation = (formData) => {
        // Perform form validation
        let newErrors = {};

        if (formData.doc_key.trim() === '') {
            newErrors.doc_key = 'Document Key Number is required.';
        }
        if(showPercentOptions) {
            if (!validateNumber(formData.qa_percent)) {
                newErrors.qa_percent = 'QAs Percent must be Number.';
            }
            if (formData.qa_percent.trim() === '') {
                newErrors.qa_percent = 'QAs Percent is required.';
            }
            if (!validateNumber(formData.pm_percent)) {
                newErrors.pm_percent = 'PMs Percent must be Number.';
            }
            if (formData.pm_percent.trim() === '') {
                newErrors.pm_percent = 'PMs Percent is required.';
            }

            if (!validateNumber(formData.team_ratio)) {
                newErrors.team_ratio = 'PMs Percent must be Number.';
            }
            if (formData.team_ratio.trim() === '') {
                newErrors.team_ratio = 'Team Ratio Percent is required.';
            }
        }

        if (formData.type_estimation.trim() === '') {
            newErrors.type_estimation = 'Estimation Type is required.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return true;
        }
        setErrors({});
        return false;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process the form data here (e.g., make an API call)
        if (!validation(formData)) {
            // Send the form data using a Fetch request
            setLoading(true);
            fetch(process.env.REACT_APP_BACKEND + 'api/estimation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.statusCode === 500) {
                        setErrors({request_error: data.message});
                        setSuccess({});
                    } else {
                        if (data.success) {
                            // Handle the response from the server
                            setSuccess({message: data.success});
                            setLink(data.link);
                            setErrors({});
                        } else {
                            setErrors({request_error: data.error});
                            setSuccess({});
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    // Handle any errors that occur during the request
                    setLoading(false);
                    setErrors({request_error: error.message});
                    setSuccess({});
                });
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form-container">

            <input
                className="form-input"
                type="text"
                name="doc_key"
                placeholder="Document Key Number"
                value={formData.doc_key}
                onChange={handleChange}
            />
            {errors.doc_key && <p className="error">{errors.doc_key}</p>}
            {showPercentOptions ?
            <input
                className="form-input"
                type="text"
                name="pm_percent"
                placeholder="PMs Percent"
                value={formData.pm_percent}
                onChange={handleChange}
            />
                : '' }
            {errors.pm_percent && <p className="error">{errors.pm_percent}</p>}
            {showPercentOptions ?
            <input
                className="form-input"
                type="text"
                name="qa_percent"
                placeholder="QAs Percent"
                value={formData.qa_percent}
                onChange={handleChange}
            />
                : '' }
            {errors.qa_percent && <p className="error">{errors.qa_percent}</p>}
            {showPercentOptions ?
            <input
                className="form-input"
                type="text"
                name="team_ratio"
                placeholder="Team Ratio Percent"
                value={formData.team_ratio}
                onChange={handleChange}
            />
            : '' }
            {errors.team_ratio && <p className="error">{errors.team_ratio}</p>}
            <select
                className="form-select"
                name="type_estimation"
                value={formData.type_estimation}
                onChange={handleChange}
            >
                <option value="">Estimation Type</option>
                <option value="pwa">PWA Project</option>
                <option value="magento">Magento 2 Project</option>
                <option value="hyva">Hyva Project</option>
                <option value="vuestorefront">VueStorefront Project</option>
            </select>
            {errors.type_estimation && <p className="error">{errors.type_estimation}</p>}
            <select
                className="form-select"
                name="handler"
                value={formData.handler}
                onChange={handleHandlerChange}
            >
                <option value="db">Estimation With DB</option>
                <option value="ai">Estimation With AI</option>
            </select>
            {errors.request_error && <p className="error">{errors.request_error}</p>}
            {success.message && <p className="success-message">{success.message} <a href={link} className="link-message" target="_blank">here.</a>  </p>}
            {loading ? (
                <div className="loading-spinner"></div>
            ) : (
                <button type="submit" className="form-button">Estimated</button>
            )}

        </form>
    );
};

export default Form;
