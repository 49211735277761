import './App.css';
import Configurations from '@gomage/configuration-form/components/configurations'

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <Configurations/>
            </header>
        </div>
    );
}

export default App;
