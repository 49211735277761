import React from 'react';
import Form from './form';

const Configurations = () => {
    return (
        <div>
            <h1>Configurations</h1>
            <Form />
        </div>
    );
};

export default Configurations;
